<script>
import props from "./mixins/commonProps";
import setMask from "@index/ext/helpers/string/setMask";
import Field from "./field";

let { props: templateProps } = props;
templateProps = Object.keys(templateProps).map((prop) => `:${prop}="${prop}"`);

const template = `
    <Field v-model="phoneModel"
           ref="field"
           ${templateProps.join(" ")}
           :mask="mask"/>
`;

const SEVEN = "+# ### #######";
const EIGHT = "# ### #######";

export default {
  name: "PhoneField",
  components: {
    Field,
  },
  mixins: [props],
  data() {
    return {
      value: "",
      mask: SEVEN,
    };
  },
  computed: {
    phoneModel: {
      get() {
        // @TODO слегка смущает конструкция
        return this.value || this.model;
      },
      set(v) {
        // @TODO напиши 8, затем выдели все и нажми +
        // маска не срабатывает моментально

        switch (v[0]) {
          case undefined:
          case "7": {
            this.mask = SEVEN;
            break;
          }
          case "8": {
            break;
          }
          case "+": {
            if (v[1] === undefined || v[1] === "7") {
              break;
            } else if (v[1] === "8") {
              this.mask = EIGHT;
              break;
            }
          }
          default: {
            this.value = "+7" + v.substr(1);
            this.mask = SEVEN;
            this.$nextTick(() => {
              this.$refs.field.field.setSelectionRange(5, 5);
            });
            return;
          }
        }

        this.value = v;
      },
    },
  },
  watch: {
    // @TODO на подумать, не создали ли мы лишних переменных
    model(v) {
      if (
        (this.phoneModel[0] === "8" ||
					this.phoneModel.substr(0, 2) === "+8") &&
				v.length > 0
      ) {
        v = "8" + v.substr(2);
      }

      this.phoneModel = v;
    },
    value(v) {
      let onlyNumbers = String(v).replace(/[^\d]/g, "");
      if (onlyNumbers) onlyNumbers = "+7" + onlyNumbers.substr(1);
      onlyNumbers = setMask(onlyNumbers, "+#(###)###-##-##");

      this.$emit("change", onlyNumbers);
    },
  },
  template,
};
</script>
