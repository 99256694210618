<template>
  <form
    novalidate
    class="phone-page"
    @submit.prevent="submit"
  >
    <h1 class="phone-page__title">
      Личный кабинет
    </h1>
    <Phone
      v-model="form.phone"
      :error="formErrors.phone"
      title="Телефон"
    />
    <div class="phone-page__actions">
      <Button
        type="submit"
        class="phone-page__next-btn"
      >
        Получить код
      </Button>
    </div>
  </form>
</template>

<script>
import "./phone-page.scss";

import validation from "@index/ext/mixins/validation";
import Validation from "@index/ext/validation/validation";

import Phone from "@index/components/common/field/phone";
import Button from "@index/components/common/gui/button/button";

export default {
  name: "PhonePage",
  components: {
    Phone,
    Button,
  },
  mixins: [validation],
  data() {
    return {
      form: {
        phone: "",
      },
      formRules: {
        phone: [Validation.REQUIRED, Validation.PHONE],
      },
    };
  },
  methods: {
    submit() {
      this.validate();

      if (!this.isValid) return;

      this.$emit("submit");
    },
    back() {
      this.$router.push({
        name: "Main",
      });
    },
  },
};
</script>
