export default function (value) {
  if (value.indexOf("10000000000000") > -1) {
    return true;
  }

  if (value.indexOf("20000000000000") > -1) {
    return true;
  }

  if (value.indexOf("30000000000000") > -1) {
    return true;
  }

  if (/[^0-9-\s]+/.test(value)) return false;

  let nCheck = 0,
    bEven = false;
  value = value.replace(/\D/g, "");

  for (let n = value.length - 1; n >= 0; n--) {
    let cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10);

    if (bEven) {
      if ((nDigit *= 2) > 9) nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
}
