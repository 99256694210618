<template>
  <button
    class="i-button"
    :class="classes"
    :type="type"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <span class="i-button__content">
      <span
        v-if="!this.$slots.icon && !withoutIcon && back"
        class="i-button__icon"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 7.00006L3.83 7.00006L9.42 1.41006L8 6.10352e-05L0 8.00006L8 16.0001L9.41 14.5901L3.83 9.00006L16 9.00006V7.00006Z"
            fill="black"
          />
        </svg>
      </span>
      <slot />
      <span
        v-if="this.$slots.icon"
        class="i-button__icon"
      >
        <slot name="icon" />
      </span>
      <span
        v-if="!this.$slots.icon && !withoutIcon && !back"
        class="i-button__icon"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.11959e-07 9L12.17 9L6.58 14.59L8 16L16 8L8 -6.99382e-07L6.59 1.41L12.17 7L7.86805e-07 7L6.11959e-07 9Z"
          />
        </svg>
      </span>
    </span>
  </button>
</template>

<script>
import "./i-button.scss";

export default {
  name: "Button",
  props: {
    type: {
      type: String,
      default: "button",
    },
    back: {
      type: Boolean,
      default: false,
    },
    withoutIcon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes: {
      get() {
        return {
          "i-button_back": this.back,
          "i-button_center": this.withoutIcon,
        };
      },
    },
  },
};
</script>
