<template>
  <form
    novalidate
    class="auth-code-page"
    @submit.prevent="submit"
  >
    <h1 class="auth-code-page__title">
      Введите код из СМС
    </h1>
    <div class="auth-code-page__form">
      <Field
        v-model="form.code"
        title="Код"
        mask="#####"
        :error="formErrors.code"
      />
      <div
        class="auth-code-page__timer"
        :class="{
          'auth-code-page__timer-inactive': timer,
        }"
      >
        <span
          v-if="!timer"
          @click="resendCode"
        >
          Отправить код повторно
        </span>
        <span v-else> {{ timer }} - Отправить код повторно </span>
      </div>
    </div>
    <div class="auth-code-page__actions">
      <Button
        type="submit"
        :disabled="disabled"
      >
        Войти
      </Button>
    </div>
  </form>
</template>

<script>
import "./auth-code-page.scss";
import Field from "@index/components/common/field/field";
import Button from "@index/components/common/gui/button/button";
import validation from "@index/ext/mixins/validation";
import Validation from "@index/ext/validation/validation";

export default {
  name: "AuthCodePage",
  components: {
    Field,
    Button,
  },
  mixins: [validation],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        code: "",
      },
      formRules: {
        code: [Validation.REQUIRED, [Validation.MIN, 5]],
      },
      timer: 60,
    };
  },
  watch: {
    "form.code"(v) {
      v.length === 5 && this.submit();
    },
  },
  created() {
    this.setTimer(60);
  },
  methods: {
    resendCode() {
      this.timer = 60;
      this.$emit("resend");
      this.setTimer(60);
    },
    submit() {
      this.validate();

      if (!this.isValid) return;

      this.$emit("submit");
    },
    setTimer(time) {
      if (time < 0) time = 0;

      this.timer = time;

      if (time === 0) return;

      setTimeout(() => {
        this.setTimer(time - 1);
      }, 1000);
    },
  },
};
</script>
